/* eslint-disable @typescript-eslint/naming-convention */
import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { ReceiptComponent } from '../shared/receipt/receipt.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  STORAGE_KEYS = Object.freeze({
    FAVOURITE_SERVICES: 'favourite-service',
    CATALOGS: 'catalogs',
    PVS: 'pvs',
    BUNDLE: 'bundle',
    TOKEN: 'token',
    USERPROFILE: 'userProfile',
    CART: 'cart',
    BOOKMARK: 'bookmark',
    ADDRESSES: 'addresses',
    THEME: 'theme',
    MALL_TOKEN: 'mall-token',
    MALL_USER_INFO: 'mallUserInfo'
  });

  private key = CryptoJS.enc.Utf8.parse(environment.encryptionSecretKey);
  private iv = CryptoJS.enc.Utf8.parse(environment.encryptionIV);

  constructor(
    private toastController: ToastController,
    public loadingController: LoadingController,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private router: Router
  ) {}

  async presentToast(message: string) {
    try {
      this.toastController
        .getTop()
        .then((v) => (v ? this.toastController.dismiss() : null));
    } catch (e) {}

    const toast = await this.toastController.create({
      message,
      cssClass: 'toast',
      duration: 4000,
      position: 'top',
      buttons: [
        {
          text: 'Close',
          role: 'cancel'
        },
      ],
    });

    return await toast.present();
  }

  async presentLoading(message) {
    try {
      this.loadingController
        .getTop()
        .then((v) => (v ? this.loadingController.dismiss() : null));
      // this.loadingController.dismiss();
    } catch (e) {
      console.log('No Loadings to close: ', e);
    } finally {
    }

    const loading = await this.loadingController.create({
      cssClass: 'loading',
      message: `${message}...`,
      showBackdrop: true,
      translucent: true,
      animated: true,
      backdropDismiss: false,
    });

    await loading.present();
  }

  async dismissLoading() {
    await this.loadingController
      .getTop()
      .then((v) => (v ? this.loadingController.dismiss() : null));
  }

  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      animated: true,
      translucent: true,
      backdropDismiss: false,
      buttons: [
        {
          text: 'DISMISS',
          role: 'cancel',
        }
      ]
    });

    await alert.present();
  }

  viewTermsAndCondition() {
    this.router.navigate(['/terms-conditions']);
  }

  openLink(url: string) {
    window.open(url, '_self')
  }

  async showReceipt(records) {
    const modal = await this.modalCtrl.create({
      component: ReceiptComponent,
      componentProps: {
        records,
      },
    });

    return await modal.present();
  }

  imgError(ev: any) {
    ev.target.src = 'assets/default_img.png';
  }

  convertCamelCaseToSentenceCase(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  breakWords(value) {
    const name = value.split(' ');
    name.splice(1, 0, '<br />');
    return name.join(' ');
  }

  hideKeyboard(ev: any) {
    if (ev.keyCode === 13) {
      ev.target.blur();
    }
  }

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.key, {
        iv: this.iv,
      }).toString(CryptoJS.format.Hex);
    } catch (e) {
      console.log('Error encrypting data', e);
    }
  }

  decryptData(data) {
    try {
      const hash = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(data));
      const bytes = CryptoJS.AES.decrypt(hash, this.key, { iv: this.iv });

      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log('Error decrypting data', e);
    }
  }

  setTheme(theme: boolean) {
    if(theme) {
      document.body.setAttribute('color-theme', 'dark')
      localStorage.setItem(this.STORAGE_KEYS.THEME, 'dark');
    } 

    if(!theme) {
      document.body.setAttribute('color-theme', 'light');
      localStorage.setItem(this.STORAGE_KEYS.THEME, 'light');
    }
  }

  setDefaultTheme() {
    const storedTheme = localStorage.getItem(this.STORAGE_KEYS.THEME);
    if (storedTheme === 'light') document.body.setAttribute('color-theme', 'light');
    else document.body.setAttribute('color-theme', 'dark');
  }

  excludeParameters(params: any) {
    delete params['Currency']
    delete params['PAN']
    delete params['CustomerName']
    delete params['TranTime']
    delete params['Order Id']

    return params
  }
}
