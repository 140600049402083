/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PayarenaService {
  private baseUrl = environment.baseUrl;
  private serverLogUrl = environment.serverLogUrl;
  
  constructor(
    private http: HttpClient,
  ) {}

  getMallHeaders() {
    const token = btoa(
      `${environment.consumerKey}:${environment.consumerSecret}`
    );
    return new HttpHeaders({
      Authorization: `Basic ${token}`,
    });
  }

  getUserProfile() {
    return this.http.get(`${this.baseUrl}/mobile/getprofile`);
  }

  // Digital Service methods
  getDashboardCatalog() {
    const services = this.http
      .get(`${this.baseUrl}/mobile/catalog`)
      .pipe(catchError((error) => of(error)));
    const verification = this.http
      .get(`${this.baseUrl}/pvs/catalog`)
      .pipe(catchError((error) => of(error)));
    return forkJoin([services, verification]);
  }

  getCatalog() {
    return this.http.get(`${this.baseUrl}/mobile/catalog`);
  }

  validateService(body) {
    return this.http.post(`${this.baseUrl}/mobile/validate`, body);
  }

  processPayment(body) {
    return this.http.post(`${this.baseUrl}/mobile/process`, body);
  }

  // Wallet
  createWallet(body: any) {
    return this.http.post(`${this.baseUrl}/mobile/create-wallet`, body);
  }

  validatePhoneNumber() {
    return this.http.get(`${this.baseUrl}/mobile/validate-phone-number`);
  }

  getWalletBalance() {
    return this.http.get(`${this.baseUrl}/mobile/balance`);
  }

  nameEnquiry(payload: { BankCode: string; AccountNumber: string }) {
    return this.http.post(`${this.baseUrl}/transfer/name-enquiry`, payload);
  }

  getTransferCommission(amount: string) {
    return this.http.get(`${this.baseUrl}/transfer/commission?amount=${amount}`);
  }

  getBanks() {
    return this.http.get(`${this.baseUrl}/mobile/get-banks`);
  }

  transfer(payload: any) {
    return this.http.post(`${this.baseUrl}/transfer/process`, payload);
  }

  IdentityStatus(){
    return this.http.get(`${this.baseUrl}/settings/check-identity-status`)
  }

  fundWallet(payload: any) {
    return this.http.post(`${this.baseUrl}/mobile/credit-wallet`, payload);
  }

  // Verification Service methods
  getVerificationServices() {
    const verification = this.http
      .get(`${this.baseUrl}/pvs/catalog`)
      .pipe(catchError((error) => of(error)));
    const bundle = this.http
      .get(`${this.baseUrl}/pvs/bundle-catalog`)
      .pipe(catchError((error) => of(error)));
    return forkJoin([verification, bundle]);
  }

  validateVerificationService(body) {
    return this.http.post(`${this.baseUrl}/pvs/validate`, body);
  }

  validateOtpService(body) {
    return this.http.post(`${this.baseUrl}/pvs/validateauthcode`, body);
  }

  processVerificationPayment(body) {
    return this.http.post(`${this.baseUrl}/pvs/process`, body);
  }

  // Bundle Service methods
  processBundlePayment(body) {
    return this.http.post(`${this.baseUrl}/pvs/process-bundle`, body);
  }

  getTermsAndConditions() {
    return this.http.get(`${this.baseUrl}/pvs/termsandconditions`);
  }

  // Verify VAS Transaction Status
  verifyVASTransactionStatus(transactionId) {
    return this.http.get(`${this.baseUrl}/mobile/status/${transactionId}`);
  }

  // Verify PVS Transaction Status
  verifyPVSTransactionStatus(transactionId) {
    return this.http.get(`${this.baseUrl}/pvs/status/${transactionId}`);
  }

  sendErrorLogToServer(error) {
    return this.http.post(`${this.serverLogUrl}/mobile/messagelog`, error);
  }
}
