/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {

  @Input() records;
  today: number = Date.now();

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {

    if(Array.isArray(this.records)) {
      return;
    } else {

      // Converts the object record to an array
      // of objects with key and value property
      const keys = Object.keys(this.records);
      const values = Object.values(this.records);
      const newRecord = keys.map((key, index) => ( { Key: key, Value: values[index] } ));

      this.records = newRecord;
    }

  }

  closeReceipt() {
    this.modalCtrl.dismiss();
  }

  gotoDashboard() {
    console.log('Dashboard');
  }

  share() {
    console.log('Share');
  }
}
