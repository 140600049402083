import { Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { Unsubscribe } from './services/unsubscribe.class';
import { LoadingService } from './services/loading.service';
import { UtilsService } from './services/utils.service';
import { MallAuthenticationService } from './mall/services/mall-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public isLoading$ = this.loadingService.loading$;
  baseUrl = environment.baseUrl;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private utils: UtilsService,
    private mallAuthService: MallAuthenticationService,
    private loadingService: LoadingService
  ) {
    console.log(router)
    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (
        event instanceof NavigationStart ||
        event instanceof RouteConfigLoadStart
      ) this.loadingService.showLoading();
      if (event instanceof NavigationEnd || event instanceof RouteConfigLoadEnd) this.loadingService.hideLoading();
    });

    this.handleAuthorization()
    this.utils.setDefaultTheme();
  }

  handleAuthorization() {
    this.route.queryParams.subscribe((paramsData: any) => {
      console.log(this.route)
      const { data }: any = paramsData;

      if (data) {
        const userData = JSON.parse(data);
        console.log('User: ', userData);
        
        const { theme, token, credentials } = userData;

        this.utils.setTheme(theme);
        if (token) localStorage.setItem(this.utils.STORAGE_KEYS.TOKEN, token);
        if(credentials) this.authenticateMallUser(credentials?.username, credentials?.password);
      }
    });
  }

  authenticateMallUser(email: string, password: string) {
    console.log({ email, password })
    this.mallAuthService.login({ email, password }).subscribe({
      next: () => console.log('Mall access has been authorized'),
    });
  }
}
