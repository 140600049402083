/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MallGuard } from './mall/services/guard/mall.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'catalog',
    loadChildren: () =>
      import('./digital-services/catalog/catalog.module').then(
        (m) => m.CatalogPageModule
      ),
  },
  {
    path: ':name/digital-service',
    loadChildren: () =>
      import('./digital-services/digital-service/digital-service.module').then(
        (m) => m.DigitalServicePageModule
      ),
  },
  {
    path: 'coming-soon',
    loadChildren: () =>
      import('./mall/coming-soon/coming-soon.module').then(
        (m) => m.ComingSoonPageModule
      ),
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('./verification-services/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'verification/pvs-service',
    loadChildren: () =>
      import('./verification-services/pvs-service/pvs-service.module').then(
        (m) => m.PvsServicePageModule
      ),
  },
  {
    path: 'verification/bundle-service',
    loadChildren: () =>
      import(
        './verification-services/bundle-service/bundle-service.module'
      ).then((m) => m.BundleServicePageModule),
  },
  {
    path: 'mall',
    loadChildren: () =>
      import('./mall/mall.module').then((m) => m.MallModule),
    // canLoad: [MallGuard],
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./mall/products/products.module').then(
        (m) => m.ProductsPageModule
      ),
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./mall/orders/orders.module').then((m) => m.OrdersPageModule),
  },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsPageModule
      ),
  },
  {
    path: 'create-wallet/:token',
    loadChildren: () =>
      import('./wallet/create-wallet/create-wallet.module').then(
        (m) => m.CreateWalletPageModule
      ),
  },
  {
    path: 'fund-wallet',
    loadChildren: () =>
      import('./wallet/fund-wallet/fund-wallet.module').then(
        (m) => m.FundWalletPageModule
      ),
  },
  {
    path: 'fund-transfer',
    loadChildren: () =>
      import('./wallet/fund-transfer/fund-transfer.module').then(
        (m) => m.FundTransferPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
