export const environment = {
  production: false,
  
  baseUrl: 'https://payarena.com:442',
  serverLogUrl: 'https://payarena.com:442',

  // baseUrl: 'https://test.payarena.com:8088',
  // serverLogUrl: 'https://test.payarena.com:8088',

  encryptionSecretKey: '240B07179B22C8B5',
  encryptionIV: 'C267E4C6C84F1A40',

  // mallUrl: 'https://payarenamall.tm-dev.xyz/ecommerce',
  // mallAuthUrl: 'https://payarenamall.tm-dev.xyz/account',
  // locationUrl: 'https://payarenamall.tm-dev.xyz/location',
  googleApiKey: 'AIzaSyBsCxugsWaw532R0gLhKYrc-b1ytFQW_7s',

  mallUrl: 'https://apimall.payarena.com/ecommerce',
  mallAuthUrl: 'https://apimall.payarena.com/account',
  locationUrl: 'https:/apimall.payarena.com/location',
 
  shipperUrl: 'https://shipper-middleware.herokuapp.com',
  consumerKey: 'ck_bd771d3dade1d5adabfdcc9d792ee8aacd29c579',
  consumerSecret: 'cs_3413f5ba1837752f78b58828d8e7b69de0d47fb4',
  provider: 'redstar'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
