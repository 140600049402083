import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSource = new BehaviorSubject<boolean>(false)
  readonly loading$ = this.loadingSource.asObservable()

  constructor() {}

  showLoading() {
    this.loadingSource.next(true)
  }

  hideLoading() {
    this.loadingSource.next(false)
  }
}
