import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilsService } from 'src/app/services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class MallAuthenticationService {
  private authUrl = environment.mallAuthUrl;
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private httpClient: HttpClient

  constructor(private handler: HttpBackend, private utils: UtilsService) {
    this.httpClient = new HttpClient(this.handler);
  }

  

  public getMallToken(): string | null {
    return localStorage.getItem(this.utils.STORAGE_KEYS.MALL_TOKEN);
  }

  private setMallToken(token: string): void {
    localStorage.setItem(this.utils.STORAGE_KEYS.MALL_TOKEN, JSON.stringify(token));
  }

  public get getMallUserInfo(): any | null {
    const userInfo: any | null = localStorage[this.utils.STORAGE_KEYS.MALL_USER_INFO];
    return userInfo && JSON.parse(userInfo);
  }

  private setMallUserInfo(userInfo: any): void {
    localStorage.setItem(this.utils.STORAGE_KEYS.MALL_USER_INFO, JSON.stringify(userInfo));
  }

  public login(payload: { email: string, password: string }): Observable<any> {
   
    return this.httpClient.post(`${this.authUrl}/login/`, payload).pipe(
      tap((result: any) => {
        console.log(result)
        const { token } = result;
        this.setMallUserInfo(result.data);
        this.setMallToken(result.data);
      })
    );
  }

  public isAuthenticated() {
    const token: string | null = this.getMallToken();
    console.log(this.getMallToken())
    // const userInfo = this.getMallUserInfo();
    // console.log('Result.data of login:', userInfo);
    return (token && !this.jwtHelper.isTokenExpired(token));
  }
}
