/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  retry,
} from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { PayarenaService } from './payarena.service';
import { LoadingService } from './loading.service';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  constructor(
    private utils: UtilsService,
    private service: PayarenaService,
    private loadingService: LoadingService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Endpoints not to set bearer token to
    if (this.excludedEndpoints(request)) {
      return next.handle(request).pipe(
        distinctUntilChanged(),
        catchError((error) => this.handleServiceError(error, request)),
        finalize(() => this.loadingService.hideLoading())
      );
    }

    const token = localStorage[this.utils.STORAGE_KEYS.TOKEN];
    const authorizedRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });

    return next.handle(authorizedRequest).pipe(
      distinctUntilChanged(),
      catchError((error) => this.handleServiceError(error, authorizedRequest)),
      finalize(() => this.loadingService.hideLoading())
    );
  }

  private excludedEndpoints(req: HttpRequest<any>): boolean {
    return (
      req.url.includes('/apimall') ||
      req.url.includes('/payarenamall') ||
      req.url.includes('/messagelog') ||
      req.url.includes('/account/login') ||
      req.url.includes('/ecommerce')
    );
  }

  private handleServiceError(
    err: HttpErrorResponse,
    request: HttpRequest<unknown>
  ) {
    const status = err.status;
    const errMessge = err?.error?.Message ?? err?.error?.detail ?? null

    switch (status) {
      case 401:
        this.utils.presentToast(
          errMessge || 'You are unauthorized, please logout and login again'
        );
        break;
      case 400:
        this.utils.presentToast(errMessge || 'Resource not found');
        break;
      case 403:
        this.utils.presentToast(errMessge || 'Forbidden To Access Resource');
        break;
      case 404:
        this.utils.presentToast(errMessge || 'Resource Not Found');
        break;
      case 0:
        this.utils.presentToast(
          errMessge || 'Please Check Your Network Connection'
        );
        break;
      case 503:
        this.utils.presentToast(
          errMessge || 'Service Unavailable, Please Try Again Later'
        );
        break;
      case 504:
        this.utils.presentToast(errMessge || 'Gateway Timeout');
        break;
      case 500:
        this.utils.presentToast(
          errMessge || 'Internal Server Error, please contact support team'
        );
        break;
      default:
        break;
    }

    // const messageToServer = {
    //   Request: request,
    //   Response: errMessge,
    //   Date: new Date(),
    //   StatusCode: err.status,
    // };

    //this.sendLogToServer(messageToServer); // Logging to Payarena Log server
    return throwError(() => err);
  }

  private sendLogToServer(messageToServer) {
    this.service
      .sendErrorLogToServer(messageToServer)
      .pipe(
        retry(1),
        catchError((err) => of(err))
      )
      .subscribe();
  }
}
